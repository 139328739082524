<!-- add this in .cam_capture-figure to make it round  border-radius: 50%;-->
<template>
	<!-- class="bg-secondary"  border-radius: 50%;-->
	<b-card-body>
		<b-row>
			<b-col xl="12">
				<b-form-group>
					<b-form-file
						v-if="!hasNoFileUploader"
						v-model="localFile"
						accept=".jpg,.png,.gif"
						ref="fileinput"
						:placeholder="FormMSG(1, 'Choose a file or drop it here...')"
						:drop-placeholder="FormMSG(2, 'Drop file here...')"
						@change="loadImage"
					/>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col xl="8">
				<b-form-group>
					<div class="cam_container">
						<vue-web-cam
							ref="webcam"
							:device-id="deviceId"
							:img="null"
							height="300"
							@started="onStarted"
							@stopped="onStopped"
							@error="onError"
							@cameras="onCameras"
							@camera-change="onCameraChange"
							@video-live="onCameraLive"
						/>
					</div>

					<!-- width="300" -->
				</b-form-group>
			</b-col>

			<b-col xl="4">
				<b-form-group>
					<b-button block variant="outline-secondary" v-if="this.cameraStarted == false" size="md" @click="onStart">
						<i class="icon-control-play"></i> {{ FormMSG(3, 'Turn on camera') }}
					</b-button>
					<b-button block variant="outline-secondary" v-if="this.cameraStarted" size="md" @click="onStop">
						<i class="icon-control-pause"></i>
						{{ FormMSG(4, 'Turn off camera') }}
					</b-button>
				</b-form-group>
				<b-form-group>
					<b-button block variant="outline-success" v-if="cameraStarted" size="md" @click="onCapture">
						<i class="icon-camera"></i> {{ FormMSG(5, 'Capture image') }}
					</b-button>
				</b-form-group>

				<b-form-group>
					<div v-bind:class="devices.length > 1 ? '' : 'sr-only'">
						<label>{{ FormMSG(6, 'Change device') }}</label>
						<select class="form-control" v-model="camera">
							<option>-- {{ FormMSG(7, 'Select Device') }} --</option>
							<option v-for="device in devices" :key="device.deviceId" :value="device.deviceId">
								{{ device.label }}
							</option>
						</select>
					</div>
				</b-form-group>

				<b-form-group v-bind:class="captured === '' ? 'sr-only' : ''">
					<figure class="figure cam_capture-figure">
						<img :src="img" alt="no picture captured" />
						<!-- width:300px;  -->
						<figcaption class="figure-caption text-center">
							{{ FormMSG(8, 'Captured image') }}
						</figcaption>
					</figure>
				</b-form-group>

				<b-button v-if="hasValidationButton" block :disabled="isStoreImgDisabled" @click="handleConfirmCapture">{{
					FormMSG(9, 'Store Picture')
				}}</b-button>
			</b-col>
		</b-row>

		<loading :active.sync="isLoading" :is-full-page="true"></loading>
	</b-card-body>
</template>

<script>
import { WebCam } from 'vue-web-cam';
import Loading from 'vue-loading-overlay';
import { reScalingImgWithCanvas } from '@/components/Packages/Captures/capture.utils';
import { store } from '@/store';

import languageMessages from '@/mixins/languageMessages';

const axios = require('axios').default;
const defaultImg = 'img/brand/M.JPG';

export default {
	name: 'CapturePictureComponent',
	components: {
		'vue-web-cam': WebCam,
		Loading
	},
	mixins: [languageMessages],
	props: {
		openned: {
			type: Boolean,
			required: false,
			default: false
		},
		hasNoFileUploader: {
			type: Boolean,
			required: false,
			default: false
		},
		hasValidationButton: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			cameraStarted: true,
			img: '' + defaultImg,
			camera: null,
			deviceId: null,
			devices: [],
			isLoading: false,
			localFile: null,
			timer: '',
			isLiveCameraLoading: true,

			captured: null
		};
	},

	computed: {
		device() {
			return this.devices.find((n) => n.deviceId === this.deviceId);
		},

		/**
		 * @return {Boolean}
		 */
		isStoreImgDisabled() {
			return this.captured === '' || this.img === defaultImg;
		}
	},
	watch: {
		devices() {
			// Once we have a list select the first one
			const [first, ...tail] = this.devices;
			if (first) {
				this.camera = first.deviceId;
				this.deviceId = first.deviceId;
			}
		},
		camera(id) {
			this.deviceId = id;
		},
		openned(status) {
			if (!status) this.$refs.webcam.stop();
		}
	},
	methods: {
		b64toFile(b64Data, filename, contentType) {
			var sliceSize = 512;
			var byteCharacters = atob(b64Data);
			var byteArrays = [];
			for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
				var slice = byteCharacters.slice(offset, offset + sliceSize);
				var byteNumbers = new Array(slice.length);
			}
		},

		async saveImage() {
			// stop the camera
			this.$refs.webcam.stop();
			this.isLoading = true;
			// var base64Data = this.img.replace(/^data:image\/jpeg;base64,/, '');
			//
			// var fil = this.b64toFile(base64Data, 'test.jpg', 'jpg');
			let formData = new FormData(); // instantiate it
			// suppose you have your file ready
			const fil = await reScalingImgWithCanvas(this.img);
			formData.set('uploadimage', fil);
			var config = {
				headers: {
					Authorization: `Bearer ${store.state.token}`,
					'content-type': 'multipart/form-data'
				}
			};
			var urlStr = process.env.VUE_APP_GQL + '/upload';
			axios
				.post(urlStr, formData, config)
				.then((response) => {
					store.state.savedImageName = response.data;
					console.log('response.data:', response.data);
				})
				.catch((error) => {
					console.log({
						error
					});
					if (error.message.includes('Network')) {
						this.warning = 'Network error.';
					} else if (error.response) {
						if (error.response.status == 401) {
							this.warning = 'Not connected.';
						} else if (error.response.status == 400) {
							this.warning = 'Invalid format.';
						} else if (error.response.status == 404) {
							this.warning = 'No TsDay.';
						}
					}
				})
				.finally(() => (this.isLoading = false));
		},
		loadImage(ev) {
			//console.log("loadImage change event",ev.type);
			//  console.log("loadImage change event ev.target.files[0]",ev.target.files[0]);
			//      console.log("loadImage change event loadImage1 event.dataTransfer.files[0]",event.dataTransfer.files[0]);

			if (ev.type == 'drop') {
				var file = event.dataTransfer.files[0];
			} else if (ev.type == 'change') {
				var file = ev.target.files[0];
			}
			//console.log("file:",file);
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.addEventListener(
				'load',
				function () {
					this.img = reader.result;
					store.state.capturedImage = reader.result;
					this.$emit('enableModalSaveButton');
				}.bind(this),
				false
			);
		},
		onCapture() {
			this.img = this.$refs.webcam.capture();
			store.state.capturedImage = this.img;
			this.captured = this.img;
			this.onStop();
			this.localFile = null;
			this.$emit('enableModalSaveButton');
		},
		onStarted(stream) {
			//  console.log("On Started Event", stream);
		},
		onStopped(stream) {
			//  console.log("On Stopped Event", stream);
		},
		onStop() {
			//  console.log("in onstop");
			this.$refs.webcam.stop();
			this.cameraStarted = false;
		},
		onStart() {
			this.isLiveCameraLoading = true;
			this.$refs.webcam.start();
			this.cameraStarted = true;
		},
		onError(error) {
			console.log('On Error Event', error);
		},
		onCameras(cameras) {
			this.devices = cameras;
			//console.log("On Cameras Event", cameras);
		},
		onCameraChange(deviceId) {
			this.deviceId = deviceId;
			this.camera = deviceId;
			//console.log("On Camera Change Event", deviceId);
		},
		onCameraLive() {
			this.isLiveCameraLoading = false;
		},
		handleConfirmCapture() {
			this.$emit('change', {
				isCaptureEnded: true,
				img: this.captured
			});
			this.captured = null;
		}
	}
};
</script>

<style lang="scss">
.cam_ {
	&container {
		background-color: #f3f4f5;
		border-radius: 1rem;
		padding: 1rem;
	}
	&capture-figure {
		display: block;
		text-align: center;
		& img {
			width: 175px;
			height: 175px;
			object-fit: cover;
			margin-left: auto;
			margin-right: auto;
			border: 1px solid #aaa;
			padding: 1px;
		}
	}
}
</style>
