var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card-body",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xl: "12" } },
            [
              _c(
                "b-form-group",
                [
                  !_vm.hasNoFileUploader
                    ? _c("b-form-file", {
                        ref: "fileinput",
                        attrs: {
                          accept: ".jpg,.png,.gif",
                          placeholder: _vm.FormMSG(
                            1,
                            "Choose a file or drop it here..."
                          ),
                          "drop-placeholder": _vm.FormMSG(
                            2,
                            "Drop file here..."
                          ),
                        },
                        on: { change: _vm.loadImage },
                        model: {
                          value: _vm.localFile,
                          callback: function ($$v) {
                            _vm.localFile = $$v
                          },
                          expression: "localFile",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xl: "8" } },
            [
              _c("b-form-group", [
                _c(
                  "div",
                  { staticClass: "cam_container" },
                  [
                    _c("vue-web-cam", {
                      ref: "webcam",
                      attrs: {
                        "device-id": _vm.deviceId,
                        img: null,
                        height: "300",
                      },
                      on: {
                        started: _vm.onStarted,
                        stopped: _vm.onStopped,
                        error: _vm.onError,
                        cameras: _vm.onCameras,
                        "camera-change": _vm.onCameraChange,
                        "video-live": _vm.onCameraLive,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xl: "4" } },
            [
              _c(
                "b-form-group",
                [
                  this.cameraStarted == false
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            block: "",
                            variant: "outline-secondary",
                            size: "md",
                          },
                          on: { click: _vm.onStart },
                        },
                        [
                          _c("i", { staticClass: "icon-control-play" }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.FormMSG(3, "Turn on camera")) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      )
                    : _vm._e(),
                  this.cameraStarted
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            block: "",
                            variant: "outline-secondary",
                            size: "md",
                          },
                          on: { click: _vm.onStop },
                        },
                        [
                          _c("i", { staticClass: "icon-control-pause" }),
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(4, "Turn off camera")) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-form-group",
                [
                  _vm.cameraStarted
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            block: "",
                            variant: "outline-success",
                            size: "md",
                          },
                          on: { click: _vm.onCapture },
                        },
                        [
                          _c("i", { staticClass: "icon-camera" }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.FormMSG(5, "Capture image")) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("b-form-group", [
                _c("div", { class: _vm.devices.length > 1 ? "" : "sr-only" }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.FormMSG(6, "Change device"))),
                  ]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.camera,
                          expression: "camera",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.camera = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c("option", [
                        _vm._v(
                          "-- " +
                            _vm._s(_vm.FormMSG(7, "Select Device")) +
                            " --"
                        ),
                      ]),
                      _vm._l(_vm.devices, function (device) {
                        return _c(
                          "option",
                          {
                            key: device.deviceId,
                            domProps: { value: device.deviceId },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(device.label) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _c(
                "b-form-group",
                { class: _vm.captured === "" ? "sr-only" : "" },
                [
                  _c("figure", { staticClass: "figure cam_capture-figure" }, [
                    _c("img", {
                      attrs: { src: _vm.img, alt: "no picture captured" },
                    }),
                    _c(
                      "figcaption",
                      { staticClass: "figure-caption text-center" },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(8, "Captured image")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm.hasValidationButton
                ? _c(
                    "b-button",
                    {
                      attrs: { block: "", disabled: _vm.isStoreImgDisabled },
                      on: { click: _vm.handleConfirmCapture },
                    },
                    [_vm._v(_vm._s(_vm.FormMSG(9, "Store Picture")))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("loading", {
        attrs: { active: _vm.isLoading, "is-full-page": true },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }